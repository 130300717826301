import React, { useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import Alert from "react-bootstrap/Alert";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { withSessionSsr } from "../dataProvider/withSession";
import Layout from "../components/layout";
import { logError } from "../dataProvider/logger";
import styles from "../styles/login.module.scss";

export default function Login() {
  const { t } = useTranslation();
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setPending(true);
    setError(null);

    const response = await fetch("/api/login", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();

    setPending(false);

    if (response.ok) return router.push(result.location);

    if (response.status === 500) logError(new Error(result.message));

    setError(t(result.translation));
  }

  return (
    <div className={`d-flex h-100 ${styles.login}`}>
      <form
        className={`mx-auto my-auto ${styles.form}`}
        onSubmit={handleSubmit}
      >
        {error && (
          <Alert variant="danger" className="text-center" onClose={() => setError(null)} dismissible>
            <p>{error}</p>
          </Alert>
        )}
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            placeholder={t("common.label.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            className="form-control"
            placeholder={t("common.label.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input type="submit" className="form-control" disabled={pending} />
        </div>
        <Link href="/register">{t("home.signup.title")}</Link>
      </form>
    </div>
  );
}

Login.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export const getServerSideProps = withSessionSsr(
  async function getServerSideProps({ locale, req }) {
    if (req.session.user)
      return {
        redirect: {
          permanent: false,
          destination: "/dashboard",
        },
      };

    return {
      props: {
        ...(await serverSideTranslations(locale, ["common"])),
      },
    };
  }
);
