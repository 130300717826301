import * as Sentry from "@sentry/nextjs";

export function logError(error) {
  console.error(error);
  Sentry.captureException(error);
}

export function handleApiError(error, req, res) {
  if (error.name === "ValidationError") {
    return res.status(400).json({ message: error.message });
  }
  if (error.status) {
    return res.status(error.status).json({ message: error.message });
  }
  logError(error);
  return res.status(500).json({ message: "Internal server error" });
}
