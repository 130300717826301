import { SSRProvider } from "react-bootstrap";
import Footer from "./footer";
import styles from "../styles/layout.module.scss";
import Header from "./header";

export default function Layout({ children, hasHeader }) {
  return (
    <SSRProvider>
      {hasHeader && <Header />}
      <div className={styles.layout}>
        <main className={`container ${styles.content}`}>{children}</main>
      </div>
      <Footer />
    </SSRProvider>
  );
}
